import React from "react";
import axios from "../utils/Axios";

import { Link } from "react-router-dom";
import { useParams } from 'react-router';

import { Footer, HomeHeader, ContentHeader, Loader } from "../components";

let baseURL = '';

// https://api.coingecko.com/api/v3/coins/tether?localization=en&tickers=true&market_data=true&sparkline=true
const Coin = () => {
    const { coin } = useParams();
    baseURL = `/coins/${coin}?localization=en&tickers=true&market_data=true&sparkline=true`;

    const [post, setPost] = React.useState(null);

    React.useEffect(() => {
        axios.get(baseURL).then((response) => {
            setPost(response.data);
        });
    }, [coin]);

    if (!post) return <Loader />;

    return (
        <>
            <div className="container-xxl mt-10">

                <div className="d-flex flex-column flex-xl-row">

                    <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">

                        <div className="card mb-5 mb-xl-8">

                            <div className="card-body">

                                <div className="d-flex flex-center flex-column py-5">

                                    <div className="symbol symbol-100px symbol-circle mb-7">
                                        <img src={post.image.large} alt="image" />
                                    </div>
                                    <span className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-3">{post.name} ({post.symbol.toUpperCase()})</span>
                                    <div className="mb-9">
                                        <div className="badge badge-lg badge-light-primary d-inline">Rank #{post.market_cap_rank}</div>
                                    </div>
                                    <div className="d-flex flex-wrap flex-center">

                                        <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                            <div className="fs-4 fw-bolder text-gray-700">
                                                <span className="w-75px">{post.market_data.high_24h.usd}</span>

                                                <span className="svg-icon svg-icon-3 svg-icon-success">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black"></rect>
                                                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black"></path>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="fw-bold text-muted">High 24h</div>
                                        </div>


                                        <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                                            <div className="fs-4 fw-bolder text-gray-700">
                                                <span className="w-50px">{post.market_data.low_24h.usd}</span>

                                                <span className="svg-icon svg-icon-3 svg-icon-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"></rect>
                                                        <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="black"></path>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="fw-bold text-muted">Low 24h</div>
                                        </div>


                                        <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                            <div className="fs-4 fw-bolder text-gray-700">
                                                <span className="w-50px">188</span>

                                                <span className="svg-icon svg-icon-3 svg-icon-success">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black"></rect>
                                                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black"></path>
                                                    </svg>
                                                </span>

                                            </div>
                                            <div className="fw-bold text-muted">Open</div>
                                        </div>

                                    </div>

                                </div>



                                <div className="d-flex flex-stack fs-4 py-3">
                                    <div className="fw-bolder rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">Details
                                        <span className="ms-2 rotate-180">

                                            <span className="svg-icon svg-icon-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"></path>
                                                </svg>
                                            </span>

                                        </span>
                                    </div>
                                </div>

                                <div className="separator"></div>

                                <div id="kt_user_view_details" className="collapse show">
                                    <div className="pb-5 fs-6">

                                        <div className="fw-bolder mt-5">Market Cap</div>
                                        <div className="text-gray-600">${post.market_data.market_cap.usd.toLocaleString()}</div>

                                        <div className="fw-bolder mt-5">24 Hour Trading Vol</div>
                                        <div className="text-gray-600">${post.market_data.total_volume.usd.toLocaleString()}</div>

                                        <div className="fw-bolder mt-5">Circulating Supply</div>
                                        <div className="text-gray-600">{post.market_data.circulating_supply.toLocaleString()}</div>

                                        <div className="fw-bolder mt-5">Total Supply</div>
                                        <div className="text-gray-600">{post.market_data.total_supply.toLocaleString()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex-lg-row-fluid ms-lg-15">

                        <div className="card">
                            <div className="card-header card-header-stretch">
                                <h3 className="card-title">Title</h3>
                                <div className="card-toolbar">
                                    <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_7">Link 1</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_8">Link 2</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_9">Link 3</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="kt_tab_pane_7" role="tabpanel">
                                        <div className="py-5" dangerouslySetInnerHTML={{
                                            __html: post.description.en
                                        }}></div>
                                    </div>

                                    <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
                                        ...
                                    </div>

                                    <div className="tab-pane fade" id="kt_tab_pane_9" role="tabpanel">
                                        ...
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
};

export default Coin;
