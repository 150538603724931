import React from "react";
import axios from "../utils/Axios";

import { Link } from "react-router-dom";
import { useParams } from 'react-router';

import { Footer, HomeHeader, ContentHeader, Loader, CSChart } from "../components";

let baseURL = '';

const Home = () => {

    const { category } = useParams();

    if (category) {
        baseURL = `coins/markets?vs_currency=usd&category=${category}&order=market_cap_desc&per_page=100&page=1&sparkline=true`;
    } else {
        baseURL = "coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=true";
    }

    const [post, setPost] = React.useState(null);

    React.useEffect(() => {
        setPost(null);
        axios.get(baseURL).then((response) => {
            setPost(response.data);
        });
    }, [category]);

    if (!post) return <Loader />;

    return (
        <>
            <HomeHeader homePage={true} pageTitle={'Cryptocurrency Current Market Prices'} />

            <div className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="card card-page flex-row-fluid">
                    <div className="card-body">
                        <div className="row gy-5 g-xl-8">
                            <div className="col-xxl-12">
                                <div className="card card-xxl-stretch">
                                    <ContentHeader />
                                    <div className="card-body py-0">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-bordered table-row-dashed gy-5">
                                                <tbody>
                                                    <tr className="text-start text-gray-400 fw-boldest fs-7 text-uppercase">
                                                        <th className="px-5"></th>
                                                        <th className="px-5">#</th>
                                                        <th className="px-5">Name</th>
                                                        <th className="px-5">Price</th>
                                                        <th className="px-5">24h %</th>
                                                        <th className="px-5">7d %</th>
                                                        <th className="px-5">Market Cap</th>
                                                        <th className="px-5">Circulating Supply</th>
                                                        <th className="px-5">Last 7 Days</th>
                                                    </tr>
                                                    {post.map(({ name, id, symbol, image, current_price, price_change_percentage_24h, market_cap, market_cap_change_24h, circulating_supply, sparkline_in_7d }, index) => (
                                                        <tr key={index}>
                                                            <td className="p-0"><Link to='#'><i className={"fas fa-light fa-star text-" + ((index === 8 || index === 5) ? 'warning' : 'secondary')}></i></Link></td>
                                                            <td className="p-0">{index + 1}</td>
                                                            <td className="pt-5">
                                                                <Link to={`/coins/${id}`}>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="symbol symbol-25px me-2">
                                                                            <span className="symbol-label">
                                                                                <img alt="" className="w-25px" src={image} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="ps-3">
                                                                            <span className="text-gray-800 fw-boldest fs-5 text-hover-primary">{name}<span className="m-5 text-gray-700 fw-bold">{symbol.toUpperCase()}</span></span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </td>
                                                            <td className="p-5">${current_price.toFixed(2)}</td>
                                                            <td className="p-5">{price_change_percentage_24h ? price_change_percentage_24h.toFixed(2) : ''}%</td>
                                                            <td className="p-5">{current_price.toLocaleString()}</td>
                                                            <td className="p-5">${market_cap.toLocaleString()}</td>
                                                            <td className="p-5">{circulating_supply.toLocaleString()} {symbol.toUpperCase()}</td>
                                                            <td className="p-5">
                                                                <CSChart id={`${id}`} data={sparkline_in_7d.price} />
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
};

export default Home;
