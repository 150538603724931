import React from "react";
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
// import { Sparklines, SparklinesBars } from 'react-sparklines';
import { Sparklines, SparklinesLine } from 'react-sparklines';
const CSChart = ({ id, data }) => {

    // ChartJS.register(
    //     CategoryScale,
    //     LinearScale,
    //     PointElement,
    //     LineElement,
    //     Title,
    //     Tooltip,
    //     Legend
    // );

    const options = {
        responsive: false,
        legend: {
            display: false
        },
        elements: {
            line: {
                borderColor: '#000000',
                borderWidth: 1
            },
            point: {
                radius: 0
            }
        },
        tooltips: {
            enabled: false
        },
        scales: {
            yAxes: [
                {
                    display: false
                }
            ],
            xAxes: [
                {
                    display: false
                }
            ]
        }

    }

    const options1 = {
        responsive: false,
        // datalabels: {
        //     display: false,
        // },
        legend: {
            display: false
        },
        plugins: {
            tooltips: {
                enabled: false
            },
            title: {
                display: false,
                enabled: false
            },
        },
    };

    return (
        <>
            {/* <Line
                datasetIdKey={`id-{${id}}`}
                options={options}
                data={{
                    labels: data,
                    datasets: [
                        {
                            data: data,
                            // borderColor: 'rgb(255, 99, 132)',
                            // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        }
                    ],
                }}
            /> */}
            {/* <Sparklines data={[5, 10, 5, 20]}>
                <SparklinesBars />
            </Sparklines> */}
            <Sparklines data={data} limit={100} width={150} height={50} margin={5}>
                <SparklinesLine color="grey" />
            </Sparklines>
        </>
    )
};

export default CSChart;
