import { Link, useLocation } from "react-router-dom";

const ContentHeader = () => {
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    const path = splitLocation[splitLocation.length - 1];
    return (

        <div className="card-header border-0 pt-5 pb-3">
            <div>
                <Link to="/watchlist" className="badge badge-secondary m-1"><i className="fas fa-light fa-star"></i> Watchlist</Link>
                <Link to="/profile" className="badge badge-secondary m-1"><i className="fas fa-solid fa-chart-pie-simple"></i> My Portfolio</Link>
                <Link to="/" className={path === "" ? " badge badge-primary m-1" : "badge badge-secondary m-1"}>All</Link>
                <Link to="/recent" className="badge text-white m-2">Recently Added</Link>
                <Link to="/categories" className={path === "categories" ? " badge badge-primary m-2" : "badge text-white m-2"}>Categories</Link>
                <Link to="/categories/metaverse" className={path === "metaverse" ? " badge badge-primary m-2" : "badge text-white m-2"}>Metaverse</Link>
                <Link to="/categories/binance-smart-chain" className={path === "binance-smart-chain" ? " badge badge-primary m-2" : "badge text-white m-2"}>BSC</Link>
                <Link to="/categories/dot-ecosystem" className={path === "dot-ecosystem" ? " badge badge-primary m-2" : "badge text-white m-2"}>Polkadot</Link>
                <Link to="/categories/solana-ecosystem" className={path === "solana-ecosystem" ? " badge badge-primary m-2" : "badge text-white m-2"}>Solana</Link>
                <Link to="/categories/avalanche-ecosystem" className={path === "avalanche-ecosystem" ? " badge badge-primary m-2" : "badge text-white m-2"}>Avalanche</Link>
            </div>
            <div className="card-toolbar">
                <div className="my-1">
                    {/* <select className="form-select fw-bold w-125px"
                                                data-control="select2" data-placeholder="Status"
                                                data-hide-search="true">
                                                <option value="1" selected="selected">Status</option>
                                                <option value="2">Pending</option>
                                                <option value="3">In Progress</option>
                                                <option value="3">Complete</option>
                                            </select> */}
                </div>
            </div>
        </div>

    )
};

export default ContentHeader;
