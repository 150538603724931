const Loader = () => {
    return (
        <div className="spinner">
            <div style={{ width: '5rem', height: '5rem' }} className="spinner-border m-5 spinneranim" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
};

export default Loader;
