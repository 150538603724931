import { Routes, Route } from "react-router-dom";
import { Layout, Home, Blogs, Contact, NoPage, Categories, Coin } from "./screens";

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route exact path="blogs" element={<Blogs />} />
          <Route exact path="contact" element={<Contact />} />
          <Route exact path="categories" element={<Categories />} />
          <Route exact path="categories/:category" element={<Home />} />
          <Route path="coins/:coin" element={<Coin />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
