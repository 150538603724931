import React from "react";
import axios from "axios";

const baseURL = "https://api.coingecko.com/api/v3/global";

const HomeHeader = ({ pageTitle, homePage }) => {
    const [post, setPost] = React.useState(null);

    React.useEffect(() => {
        axios.get(baseURL).then((response) => {
            setPost(response.data);
        });
    }, []);

    if (!post) return null;

    return (
        <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
            <div className="container-xxl d-flex flex-stack flex-wrap">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder text-dark">{pageTitle}</span>
                    {!homePage ?
                        <span className="text-gray-600 w-400px fs-5 fw-bold pt-4 d-block">We have created an index for each cryptocurrency category. Categories are ranked by 24h price change. Click on a crypto category name to see the constituent parts of the index and their recent price performance.</span>
                        :
                        <span className="text-muted mt-5 fw-bold fs-7 d-block">Cryptos:<span className="text-primary">{post.data.active_cryptocurrencies.toLocaleString()}</span> Market Cap:<span className="text-primary">${post.data.total_volume.usd.toLocaleString()}</span></span>
                    }

                </h3>
                <div className="d-flex align-items-center flex-wrap py-2">

                    <div className="d-flex align-items-center w-200px w-lg-250px my-2 me-4 me-lg-6"
                        data-kt-search-keypress="true" data-kt-search-min-length="2"
                        data-kt-search-enter="enter" data-kt-search-layout="menu" data-kt-menu-trigger="auto"
                        data-kt-menu-permanent="true" data-kt-menu-placement="bottom-end">
                        <form data-kt-search-element="form" className="search w-100 position-relative">
                            <input type="hidden" />
                            <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-white position-absolute top-50 translate-middle-y ms-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                                        rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="black" />
                                </svg>
                            </span>
                            <input type="text" className="form-control ps-15" name="search" defaultValue=""
                                placeholder="Search..." data-kt-search-element="input" />
                            <span
                                className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4"
                                data-kt-search-element="clear">
                                <span className="svg-icon svg-icon-2 svg-icon-white svg-icon-lg-1 me-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                            transform="rotate(-45 6 17.3137)" fill="black" />
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                            transform="rotate(45 7.41422 6)" fill="black" />
                                    </svg>
                                </span>
                            </span>
                        </form>

                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    )
};

export default HomeHeader;