import React, { useEffect } from "react";
import axios from "../utils/Axios";

import { Link, useParams } from "react-router-dom";
import { Footer, HomeHeader, ContentHeader, Loader } from "../components";

const baseURL = "/coins/categories";
// const baseURL = "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=solana-ecosystem&order=market_cap_desc&per_page=100&page=1&sparkline=false";
// const baseURL = "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=stablecoins&order=market_cap_desc&per_page=100&page=1&sparkline=false";

function Categories() {

    const [post, setPost] = React.useState(null);

    const { category } = useParams();
    useEffect(() => {
        setPost(null);
        axios.get(baseURL).then((response) => {
            setPost(response.data);
        });
    }, [category]);

    if (!post) return <Loader />;

    return (
        <>
            <HomeHeader pageTitle={'Cryptocurrency Category by 24h Price Change'} />

            <div className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="card card-page flex-row-fluid">
                    <div className="card-body">
                        <div className="row gy-5 g-xl-8">
                            <div className="col-xxl-12">
                                <div className="card card-xxl-stretch">
                                    <ContentHeader />
                                    <div className="card-body py-0">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-bordered table-row-dashed gy-5">
                                                <tbody>
                                                    <tr className="text-start text-gray-400 fw-boldest fs-7 text-uppercase">
                                                        <th className="px-5">#</th>
                                                        <th className="px-5">Name</th>
                                                        <th className="px-5">Avg. Price Change</th>
                                                        <th className="px-5">Top Gainers</th>
                                                        <th className="px-5">Market Cap</th>
                                                        <th className="px-5">Volume</th>
                                                    </tr>
                                                    {post.map(({ name, id, symbol, market_cap, market_cap_change_24h, volume_24h, top_3_coins }, index) => (
                                                        <tr key={index}>
                                                            <td className="p-0">{index + 1}</td>
                                                            <td className="pt-5">
                                                                <Link to={`/categories/${id}`}>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="ps-3">
                                                                            <span href="#" className="text-gray-800 fw-boldest fs-5 text-hover-primary">{name}</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </td>
                                                            <td className="p-5">${market_cap.toFixed(2)}</td>
                                                            <td>
                                                                <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                                                                    {top_3_coins.map((item, index) => (
                                                                        <div key={index} className="symbol symbol-circle symbol-25px me-2">
                                                                            <span className="symbol-label">
                                                                                <img className="w-25px" src={item} alt="img" />
                                                                            </span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                            <td className="p-5">{market_cap_change_24h ? market_cap_change_24h.toFixed(2) : ''}%</td>
                                                            <td className="p-5">{volume_24h ? volume_24h : ''}</td>
                                                            <td className="p-5"></td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
};

export default Categories;
