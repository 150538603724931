import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div
                className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-bold me-1">2021©</span>
                    <Link to={{ pathname: "https://mindzenmedia.com" }} target="_blank"
                        className="text-gray-800 text-hover-primary">CoinScape</Link>
                </div>
                <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                    <li className="menu-item">
                        <Link to={{ pathname: "https://mindzenmedia.com" }} target="_blank" className="menu-link px-2">About</Link>
                    </li>
                    <li className="menu-item">
                        <Link to={{ pathname: "https://www.mindzenmedia.com/support" }} target="_blank"
                            className="menu-link px-2">Support</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default Footer;
