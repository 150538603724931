/* @flow weak */

import * as axios from "axios";

// var userData = fn.getUserData();

var instance = axios.create({
  baseURL: "https://api.coingecko.com/api/v3/",
  timeout: 90000,
  withCredentials: false,
  maxRedirects: 25,
  cache: "no-cache",
});

instance.interceptors.request.use(
  async config => {

    // const accessToken = await AsyncStorage.getItem('accessToken')

    // if (accessToken) {
    //   console.warn(accessToken)
    //   config.headers['X-User-Ac'] = accessToken;
    // }

    return config
  },
  error => {
    return Promise.reject(error)
  }
);

instance.all = axios.all;

export { instance as default };
